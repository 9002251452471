import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { UseQueryResult } from "react-query";
import type { Space } from "@/react/types/Space";
import { useSpaceResource, useSpacesContext } from "@circle-react/contexts";

interface CurrentSpaceContextProviderProps {
  spaceId?: number;
  children: ReactNode;
}

type SpaceQueryResult = UseQueryResult<Space>;
type EmptyRecord = Record<string, never>;
type SpaceContextValue = SpaceQueryResult | EmptyRecord;

const CurrentSpaceContext = createContext<SpaceContextValue>({});
CurrentSpaceContext.displayName = "CurrentSpaceContext";

export const CurrentSpaceContextProvider = ({
  spaceId,
  children,
}: CurrentSpaceContextProviderProps) => {
  const { helpers } = useSpacesContext();
  const placeholderData = helpers.findBy({ id: spaceId });

  // The query data may initially contain a space with missing fields while
  // the query is loading. Notably, `lock_screen_description`, `course_setting`
  // and `course_sections`.
  //
  // This happens because we set the placeholder data from the spaces index API
  // response while loading the full space object from the show API. This is
  // done mainly to improve the UX when switching between spaces.
  //
  // You can use the `useSpaceResource` hook directly if you need to ensure
  // that you're working with the complete version of the space.
  const value = useSpaceResource({
    spaceId,
    queryOptions: { placeholderData },
  });

  return (
    <CurrentSpaceContext.Provider value={value}>
      {children}
    </CurrentSpaceContext.Provider>
  );
};

// TODO: Enforce that this is only used within CurrentSpaceContextProvider.
// Currently, this hook is used outside the context and relies on the default
// value being an empty object. See `usePostList` in `<StandardLayoutV2>`.
export const useCurrentSpaceContext = () => useContext(CurrentSpaceContext);
