import type { FetchOptions } from "../helpers/backendRequestHelpers";
import {
  fetchRequest,
  reactQueryGet,
  reactQueryPost,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";
import type { Space } from "../types/Space";

export interface IndexParams extends FetchOptions {
  params: Record<string, unknown>;
}

export interface IndexResponseData {
  count: number;
  has_next_page: boolean;
  page: number;
  per_page: number;
  sidebar_html: string;
  records: Space[];
}

const index = ({
  params = {},
  ...fetchRequestOptions
}: IndexParams): Promise<Response> =>
  fetchRequest(internalApi.spaces.index({ params }), {
    ...fetchRequestOptions,
    method: "GET",
  });

export interface ShowParams extends FetchOptions {
  id?: number;
}

const show = ({ id, ...fetchRequestOptions }: ShowParams): Promise<any> =>
  fetchRequest(internalApi.spaces.show({ id }), {
    ...fetchRequestOptions,
    method: "GET",
  });

export interface ListParams extends FetchOptions {
  params: Record<string, unknown>;
}

const list = ({ params = {} }: ListParams): Promise<any> =>
  reactQueryGet(internalApi.spaces.index({ params }));

export interface AddMemberToSpaceParams {
  spaceId: number;
  body: Record<string, unknown>;
}

const addMemberToSpace = ({
  spaceId,
  body,
}: AddMemberToSpaceParams): Promise<any> =>
  reactQueryPost(internalApi.spaces.addMemberToSpace({ spaceId }), body);

export interface DestroyParams extends FetchOptions {
  id: number;
}

const destroy = ({ id, ...fetchRequestOptions }: DestroyParams): Promise<any> =>
  fetchRequest(internalApi.spaces.destroy({ id }), {
    ...fetchRequestOptions,
    method: "DELETE",
  });

export const spaceApi = {
  index,
  list,
  show,
  addMemberToSpace,
  destroy,
};
