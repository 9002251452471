import { t } from "@/i18n-js/instance";
import { ApiError } from "@circle-react/config/CustomErrors";
import { safeJsonParse } from "./safeJsonParse";

export interface FetchOptions extends RequestInit {
  headers?: Record<string, string>;
  forReactQuery?: boolean;
}

export type OptionsType = FetchOptions | undefined;

export interface ErrorJsonBody {
  failure?: {
    message: string;
  };
  view_only_masquerading?: boolean;
  message?: string;
  error_details?: string;
}

export const serializeJsonResponse = async <T = any>(
  response: Response,
): Promise<T> => {
  if (response.ok) {
    const text = await response.text();
    if (text.length > 0) {
      return safeJsonParse<T>(text) || ({} as T);
    }
    return {} as T;
  }

  const json: ErrorJsonBody = (await response.json()) as ErrorJsonBody;
  const { status, statusText } = response;

  if (json?.view_only_masquerading) {
    throw new ApiError({
      message: t("view_only_error"),
      status,
      statusText,
      errorDetails: t("view_only_error"),
      body: json,
    });
  }

  const message =
    json.message || json.failure?.message || "Failed to retrieve data!";

  const { error_details = json.message } = json;

  throw new ApiError({
    message,
    status,
    statusText,
    errorDetails: error_details,
    body: json,
  });
};

export async function fetchRequest<T = any>(
  url: string,
  options: OptionsType = {},
): Promise<T> {
  const {
    headers = {},
    forReactQuery: isForReactQuery,
    ...passThroughOptions
  } = options;

  const response = await fetch(url, {
    ...passThroughOptions,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    credentials: "same-origin",
  });

  if (!isForReactQuery) {
    return response as T;
  }

  return serializeJsonResponse<T>(response);
}

export const transformToFormData = (
  data: object,
  formData = new FormData(),
  parentKey = "",
) => {
  for (const [key, value] of Object.entries(data)) {
    const formattedKey = parentKey ? `${parentKey}[${key}]` : key;

    if (value instanceof File) {
      formData.set(formattedKey, value);
    } else if (value instanceof Array) {
      value.forEach((element: string | Blob) => {
        formData.append(`${formattedKey}[]`, element);
      });
    } else if (value instanceof Object) {
      transformToFormData(value as object, formData, formattedKey);
    } else {
      formData.set(formattedKey, value as string | Blob);
    }
  }
  return formData;
};

export const reactQueryGet = <T = any>(url: string): Promise<T> =>
  fetchRequest(url, {
    method: "GET",
    forReactQuery: true,
  });

export const reactQueryPut = <T = any>(
  url: string,
  bodyParams?: object,
): Promise<T> =>
  fetchRequest(url, {
    method: "PUT",
    body: JSON.stringify(bodyParams),
    forReactQuery: true,
  });

export const reactQueryPatch = <T = any>(
  url: string,
  bodyParams?: object,
): Promise<T> =>
  fetchRequest(url, {
    method: "PATCH",
    body: JSON.stringify(bodyParams),
    forReactQuery: true,
  });

export const reactQueryPost = <T = any>(
  url: string,
  bodyParams?: object,
): Promise<T> =>
  fetchRequest(url, {
    method: "POST",
    body: JSON.stringify(bodyParams),
    forReactQuery: true,
  });

export const reactQueryDelete = <T = any>(
  url: string,
  bodyParams?: object,
): Promise<T> => {
  const options: FetchOptions = {
    method: "DELETE",
    forReactQuery: true,
  };

  if (bodyParams) {
    options.body = JSON.stringify(bodyParams);
  }

  return fetchRequest(url, options);
};
